<template>
    <div class="maincontent">
        <div class="container">
            <div class="row d-flex justify-content-center" style="margin-top: 4rem" v-if="flat">
                <div class="col-12 col-md-5 text-center">
                    <!-- <h5> Grundriss für die Wohnung {{flatNbm}} </h5> -->
                    <img style="max-width:100%" :src="require(`@/assets/Bauplaene/SingleFlats/${flat}.png`)" alt="">
                </div>
                <div class="col-12 col-md-3 description">
                    <h5 class="mb-4">{{flatInfos.id}}</h5>
                    <span class="mr-4">Anzahl Zimmer: {{flatInfos.Zimmer}} </span> 
                    <!-- <span>Quadratmeter Total: {{flatInfos.Wohnfläche[flatInfos.Wohnfläche.length-1].qm}} qm</span>  -->
                    <h5 class="mt-4 mb-2">Zimmeraufteilung</h5>
                    <table style="min-width:100%">
                        <thead>
                            <tr>
                                <th>Zimmerart</th>
                                <th>Quadratmeter</th>
                                <!-- <th>Faktor</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(room,index) in flatInfos.Wohnfläche" :key="index">
                                <td> {{room.typ}} </td>
                                <td v-if="room.typ == 'Gesamt'" class="font-weight-bold"> {{room.qm}} m²</td>
                                <td v-else> {{room.qm}} m²</td>
                                <!-- <td> {{room.faktor}} </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <h5 class="mt-4 mb-2">Vorraussichtliche Miete in €</h5>
                    <table style="min-width:100%">
                        <thead>
                            <tr>
                                <th>Kaltmiete</th>
                                <th>Nebenkosten</th>
                                <th>Warm-Miete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> {{flatInfos.miete.kalt}} </td>
                                <td> {{flatInfos.miete.nk}} </td>
                                <td> {{flatInfos.miete.warm}} </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="mt-2">PKW-Stellplatz mtl. 95,00 <br> mit E-Ladeplatz mtl. 135,00</p>
                    <small class="text-muted mb-2">Nebenkosten beinhalten Betriebskosten und Heizkosten</small>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-8 mt-4">
                    <b-card header="Kontaktformular" header-class="h3">
                        <b-form class="form" @submit="onSubmit" @reset="onReset" v-if="show">
                            <b-form-group
                                id="input-group-1"
                                label="E-Mail Adresse:"
                                label-for="input-1"
                            >
                                <b-form-input
                                id="input-1"
                                name="user_email"
                                v-model="form.email"
                                type="email"
                                required
                                placeholder="Bitte geben Sie ihre E-Mail Adresse ein..."
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="input-group-2" label="Ihr Name:" label-for="input-2">
                                <b-form-input
                                id="input-2"
                                name="user_name"
                                v-model="form.name"
                                required
                                placeholder="Bitte geben Sie ihren Namen an..."
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="text-group-1" label="Ihre Nachricht:" label-for="text-1">
                                <b-form-textarea
                                name="message"
                                id="text-1"
                                v-model="form.message"
                                required
                                placeholder="Bitte geben Sie ihren Nachricht an..."
                                ></b-form-textarea>
                            </b-form-group>

                            <b-button type="submit" style="float:right" variant="secondary">Absenden</b-button>
                            <b-button type="reset"  variant="danger">Abbrechen</b-button>
                        </b-form>
                    </b-card>
                </div>
            </div>
        </div>
        <b-modal ref="merci" title="Vielen Dank" centered ok-title="Ok" ok-variant="secondary" ok-only @ok="closeModal">
            <div class="success-cont">
                <div class="f-modal-icon f-modal-success animate">
                    <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                    <span class="f-modal-line f-modal-long animateSuccessLong"></span>
                    <div class="f-modal-placeholder2"></div>
                    <div class="f-modal-placeholder"></div>
                    <div class="f-modal-fix"></div>
                </div>
            </div>
            <span>Vielen Dank für Ihre Anfrage.<br>
            Wir werden uns schnellstmöglich bei Ihnen melden.</span>
        </b-modal>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  name: 'Contact',
  components: {
    //   emailjs
  },
    data (){
        return {
            flat: this.$route.params.id,
            
            form: {
                name: '',
                message:'',
                email:''
            },
            show: true,
        }
    },
    created(){
        if(this.flat) this.form.message = 'Bezüglich meines Interesses an der Wohnung ' + this.flat
    },
    methods:{
        onSubmit(evt) {
            evt.preventDefault()
            this.$refs['merci'].show()
            // console.log(evt)
             emailjs.sendForm('service_a0jpann', 'template_lwk0wwb', evt.target, 'user_PDWQWmElrGj06EMZ1cRDW')
                .then((result) => {
                }, (error) => {
                    console.log('FAILED...', error);
                });
        },
        openModal(){
            this.$refs['merci'].show()
        },
        closeModal(){
            this.form.email = ''
            this.form.name = ''
            this.form.message = ''
        },
        onReset(evt) {
            evt.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
        
    },
    computed:{
        flatInfos(){
            let activeFlat= ''
            var options = [
                {id:'WE_01',
                Zimmer:2,
                Wohnfläche:[
                    { typ:'Flur',qm:4.56,faktor:1},
                    { typ:'Bad',qm:5.99,faktor:1},
                    { typ:'Schlafen',qm:13.58,faktor:1},
                    { typ:'Wohnen/Kochen',qm:21.31,faktor:1},
                    { typ:'Terrasse',qm:4.57,faktor:0.5},
                    { typ:'Gesamt',qm:47.73},
                ],
                miete:{
                    kalt:828.60,
                    nk:138.00,
                    warm:966.60
                }},
                {id:'WE_02',
                Zimmer:3,
                Wohnfläche:[
                    { typ:'Flur',qm:8.67,faktor:1},
                    { typ:'Bad',qm:7.23,faktor:1},
                    { typ:'Schlafen',qm:14.32,faktor:1},
                    { typ:'Zimmer 1',qm:12.57,faktor:1},
                    { typ:'Wohnen/Kochen',qm:33.97,faktor:1},
                    { typ:'Abstell',qm:1.77,faktor:1},
                    { typ:'Terrasse',qm:21.05,faktor:0.5},
                    { typ:'Gesamt',qm:89.06},
                ],
                miete:{
                    kalt:1416.00,
                    nk:258.00,
                    warm:1674.00
                }},
                {id:'WE_03',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur', qm:4.22,faktor:1},
                    { typ: 'Bad',qm:6.91,faktor:1},
                    { typ: 'Schlafen',qm:13.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:22.63,faktor:1},
                    { typ: 'Loggia',qm:4.30,faktor:0.5},
                    { typ: 'Gesamt',qm:49.48},
                ],
                miete:{
                    kalt:864.50,
                    nk:143.00,
                    warm:1007.50
                }},
                {id:'WE_04',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.15,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:14.32,faktor:1},
                    { typ: 'Zimmer 1',qm:12.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.42,faktor:1},
                    { typ: 'Abstell',qm:1.77,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:78.20},
                ],
                miete:{
                    kalt:1271.00,
                    nk:227.00,
                    warm:1498.00
                }},
                {id:'WE_05',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.83,faktor:1},
                    { typ: 'Bad',qm:8.46,faktor:1},
                    { typ: 'Schlafen',qm:15.07,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.89,faktor:1},
                    { typ: 'Abstell',qm:3.82,faktor:1},
                    { typ: 'Loggia',qm:4.42,faktor:0.5},
                    { typ: 'Gesamt',qm:85.04},
                ],
                miete:{
                    kalt:1347.90,
                    nk:247.00,
                    warm:1594.90
                }},
                {id:'WE_06',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:4.22,faktor:1},
                    { typ: 'Bad',qm:6.91,faktor:1},
                    { typ: 'Schlafen',qm:13.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:22.63,faktor:1},
                    { typ: 'Loggia',qm:4.30,faktor:0.5},
                    { typ: 'Gesamt',qm:47.73},
                ],
                miete:{
                    kalt:880.80,
                    nk:143.00,
                    warm:1023.80
                }},
                {id:'WE_07',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.15,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:14.32,faktor:1},
                    { typ: 'Zimmer 1',qm:12.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.42,faktor:1},
                    { typ: 'Abstell',qm:1.77,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:78.20},
                ],
                miete:{
                    kalt:1302.00,
                    nk:230.00,
                    warm:1532.00
                }},
                {id:'WE_08',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.83,faktor:1},
                    { typ: 'Bad',qm:8.46,faktor:1},
                    { typ: 'Schlafen',qm:15.07,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.89,faktor:1},
                    { typ: 'Abstell',qm:3.82,faktor:1},
                    { typ: 'Loggia',qm:4.42,faktor:0.5},
                    { typ: 'Gesamt',qm:85.04},
                ],
                miete:{
                    kalt:1392.00,
                    nk:256.00,
                    warm:1648.00
                }},
                {id:'WE_09B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:11.62,faktor:1},
                    { typ: 'Bad',qm:6.70,faktor:1},
                    { typ: 'Schlafen',qm:18.05,faktor:1},
                    { typ: 'Zimmer 1',qm:10.46,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.69,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:79.26},
                ],
                miete:{
                    kalt:1387.00,
                    nk:230.00,
                    warm:1617.00
                }},
                {id:'WE_10',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.47,faktor:1},
                    { typ: 'Bad',qm:8.42,faktor:1},
                    { typ: 'Schlafen',qm:11.55,faktor:1},
                    { typ: 'Zimmer 1',qm:9.33,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:28.16,faktor:1},
                    { typ: 'Abstell',qm:3.73,faktor:1},
                    { typ: 'Loggia',qm:8.55,faktor:0.5},
                    { typ: 'Gesamt',qm:72.94},
                ],
                miete:{
                    kalt:1283.90,
                    nk:211.00,
                    warm:1494.90
                }},
                {id:'WE_11B',
                Zimmer:3,
                Wohnfläche:[
                    { typ : 'Flur',qm:8.51,faktor:1},
                    { typ : 'Bad',qm:8.49,faktor:1},
                    { typ : 'Schlafen',qm:14.30,faktor:1},
                    { typ : 'Zimmer 1',qm:12.18,faktor:1},
                    { typ : 'Wohnen/Kochen',qm:33.89,faktor:1},
                    { typ : 'Abstell',qm:2.71,faktor:1},
                    { typ : 'Terrasse',qm:9.26,faktor:0.5},
                    { typ : 'Gesamt',qm:84.71},
                ],
                miete:{
                    kalt:1355.40,
                    nk:241.00,
                    warm:1596.40
                }},
                {id:'WE_12',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Terrasse',qm:7.27,faktor:0.5},
                    { typ: 'Gesamt',qm:27.51},
                ],
                miete:{
                    kalt:619.00,
                    nk:83.00,
                    warm:702.00
                }},
                {id:'WE_13',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.68,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.69,faktor:1},
                    { typ: 'Terrasse',qm:7.90,faktor:0.5},
                    { typ: 'Gesamt',qm:52.57},
                ],
                miete:{
                    kalt:867.40,
                    nk:156.00,
                    warm:1023.40
                }},
                {id:'WE_14B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.51,faktor:1},
                    { typ: 'Bad',qm:8.59,faktor:1},
                    { typ: 'Schlafen',qm:14.30,faktor:1},
                    { typ: 'Zimmer 1',qm:12.18,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:34.25,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Loggia',qm:5.08,faktor:0.5},
                    { typ: 'Gesamt',qm:83.08},
                ],
                miete:{
                    kalt:1345.90,
                    nk:240.00,
                    warm:1585.90
                }},
                {id:'WE_15',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Gesamt',qm:23.87},
                ],
                miete:{
                    kalt:561.00,
                    nk:73.00,
                    warm:634.00
                }},
                {id:'WE_16',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.87,faktor:1},
                    { typ: 'Loggia',qm:4.18,faktor:0.5},
                    { typ: 'Gesamt',qm:51.00},
                ],
                miete:{
                    kalt:872.10,
                    nk:150.00,
                    warm:1022.10
                }},
                {id:'WE_17B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.51,faktor:1},
                    { typ: 'Bad',qm:8.59,faktor:1},
                    { typ: 'Schlafen',qm:14.30,faktor:1},
                    { typ: 'Zimmer 1',qm:12.18,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:34.25,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Loggia',qm:5.08,faktor:0.5},
                    { typ: 'Gesamt',qm:83.08},
                ],
                miete:{
                    kalt:1362.50,
                    nk:240.00,
                    warm:1602.50
                }},
                {id:'WE_18',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Gesamt',qm:23.87},
                ],
                miete:{
                    kalt:580.00,
                    nk:73.00,
                    warm:653.00
                }},
                {id:'WE_19',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.87,faktor:1},
                    { typ: 'Loggia',qm:4.18,faktor:0.5},
                    { typ: 'Gesamt',qm:51.00},
                ],
                miete:{
                    kalt:902.70,
                    nk:151.00,
                    warm:1053.70
                }},
                {id:'WE_20',
                Zimmer:4,
                Wohnfläche:[
                    { typ: 'Flur',qm:12.28,faktor:1},
                    { typ: 'Bad',qm:7.15,faktor:1},
                    { typ: 'WC',qm:2.32,faktor:1},
                    { typ: 'Schlafen',qm:17.01,faktor:1},
                    { typ: 'Zimmer 1',qm:12.32,faktor:1},
                    { typ: 'Zimmer 2',qm:12.30,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:33.00,faktor:1},
                    { typ: 'Abstell',qm:2.80,faktor:1},
                    { typ: 'Dachterrasse',qm:27.65,faktor:0.5},
                    { typ: 'Gesamt',qm:113.01},
                ],
                miete:{
                    kalt:1932.50,
                    nk:330.00,
                    warm:2262.50
                }},
                {id:'WE_21',
                Zimmer:4,
                Wohnfläche:[
                    { typ: 'Flur',qm:14.57,faktor:1},
                    { typ: 'Bad',qm:6.22,faktor:1},
                    { typ: 'WC',qm:2.87,faktor:1},
                    { typ: 'Schlafen',qm:16.72,faktor:1},
                    { typ: 'Zimmer 1',qm:12.49,faktor:1},
                    { typ: 'Zimmer 2',qm:8.35,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:27.34,faktor:1},
                    { typ: 'Abstell',qm:2.79,faktor:1},
                    { typ: 'Dachterrasse',qm:21.42,faktor:0.5},
                    { typ: 'Gesamt',qm:102.06},
                ],
                miete:{
                    kalt:1786.05,
                    nk:300.00,
                    warm:2086.05
                }},
                {id:'WE_22',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.78,faktor:1},
                    { typ: 'Zimmer 1',qm:10.94,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.94,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Terrasse',qm:9.62,faktor:0.5},
                    { typ: 'Gesamt',qm:86.06},
                ],
                miete:{
                    kalt:1428.60,
                    nk:252.00,
                    warm:1680.60
                }},
                {id:'WE_23',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.49,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Terrasse',qm:21.12,faktor:0.5},
                    { typ: 'Gesamt',qm:85.48},
                ],
                miete:{
                    kalt:1402.00,
                    nk:250.00,
                    warm:1652.00
                }},
                {id:'WE_24B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.76,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.51,faktor:1},
                    { typ: 'Terrasse',qm:9.22,faktor:0.5},
                    { typ: 'Gesamt',qm:79.02},
                ],
                miete:{
                    kalt:1264.30,
                    nk:235.00,
                    warm:1499.30
                }},
                {id:'WE_25',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.33,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.95,faktor:1},
                    { typ: 'Abstell',qm:3.07,faktor:1},
                    { typ: 'Loggia',qm:4.43,faktor:0.5},
                    { typ: 'Gesamt',qm:83.85},
                ],
                miete:{
                    kalt:1394.00,
                    nk:244.00,
                    warm:1638.00
                }},
                {id:'WE_26',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.49,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:79.66},
                ],
                miete:{
                    kalt:1380.00,
                    nk:232.00,
                    warm:1612.00
                }},
                {id:'WE_27B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.87,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.87,faktor:1},
                    { typ: 'Loggia',qm:5.04,faktor:0.5},
                    { typ: 'Gesamt',qm:77.40},
                ],
                miete:{
                    kalt:1284.80,
                    nk:227.00,
                    warm:1511.80
                }},
                {id:'WE_28',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.33,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.94,faktor:1},
                    { typ: 'Abstell',qm:3.07,faktor:1},
                    { typ: 'Loggia',qm:4.43,faktor:0.5},
                    { typ: 'Gesamt',qm:83.84},
                ],
                miete:{
                    kalt:1458.80,
                    nk:244.00,
                    warm:1702.50
                }},
                {id:'WE_29',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.57,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Loggia',qm:9.49,faktor:0.5},
                    { typ: 'Gesamt',qm:79.75},
                ],
                miete:{
                    kalt:1408.00,
                    nk:232.00,
                    warm:1640.00
                }},
                {id:'WE_30B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.87,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.87,faktor:1},
                    { typ: 'Loggia',qm:5.04,faktor:0.5},
                    { typ: 'Gesamt',qm:77.40},
                ],
                miete:{
                    kalt:1401.00,
                    nk:227.00,
                    warm:1628.00
                }},
                {id:'WE_31',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.03,faktor:1},
                    { typ: 'Bad',qm:7.89,faktor:1},
                    { typ: 'Schlafen',qm:11.41,faktor:1},
                    { typ: 'Zimmer 1',qm:8.51,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:28.40,faktor:1},
                    { typ: 'Abstell',qm:3.52,faktor:1},
                    { typ: 'Loggia',qm:8.80,faktor:0.5},
                    { typ: 'Gesamt',qm:72.16},
                ],
                miete:{
                    kalt:1328.00,
                    nk:210.00,
                    warm:1538.00
                }},
                {id:'WE_32B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:9.55,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:16.17,faktor:1},
                    { typ: 'Zimmer 1',qm:11.45,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.74,faktor:1},
                    { typ: 'Abstell',qm:1.73,faktor:1},
                    { typ: 'Loggia',qm:9.49,faktor:0.5},
                    { typ: 'Dachterrasse',qm:15.88,faktor:0.5},
                    { typ: 'Gesamt',qm:88.12},
                ],
                miete:{
                    kalt:1632.00,
                    nk:260.00,
                    warm:1892.00
                }},
            ]

            for (let i = 0; i < options.length; i++) {
                const element = options[i];
                if(this.flat && element.id == this.flat) activeFlat = element
            }
            // console.log(activeFlat)
            return activeFlat
        }
        
    },

}
</script>

<style scoped>
.container{
    min-height: 100vh;
}
.description{
    font-size: 1rem;
}
::v-deep .modal-backdrop{
    opacity: 0.4 !important;
}
.success-cont{
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px;
}

.f-modal-icon.f-modal-success,
.f-modal-icon.f-modal-error {
  border-color: #A5DC86;
  cursor: pointer;
}

.f-modal-icon.f-modal-success:after,
.f-modal-icon.f-modal-success:before,
.f-modal-icon.f-modal-error:after,
.f-modal-icon.f-modal-error:before {
  background: #fff;
  content: '';
  height: 120px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 60px;
}

.f-modal-icon.f-modal-success:before,
.f-modal-icon.f-modal-error:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  -webkit-transform-origin: 60px 60px;
          transform-origin: 60px 60px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.f-modal-icon.f-modal-success:after,
.f-modal-icon.f-modal-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  -webkit-transform-origin: 0 60px;
          transform-origin: 0 60px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.f-modal-icon.f-modal-success .f-modal-placeholder,
.f-modal-icon.f-modal-error .f-modal-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.f-modal-icon.f-modal-success .f-modal-placeholder2 {
  animation: pulseWarning 2s linear infinite;
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}

.f-modal-icon.f-modal-success .f-modal-fix,
.f-modal-icon.f-modal-error .f-modal-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 7px;
  z-index: 1;
}

.f-modal-icon.f-modal-success .f-modal-line,
.f-modal-icon.f-modal-error .f-modal-line {
  background-color: #A5DC86;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 3;
}

.f-modal-icon.f-modal-success .f-modal-line.f-modal-tip,
.f-modal-icon.f-modal-error .f-modal-line.f-modal-tip {
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 25px;
}

.f-modal-icon.f-modal-success .f-modal-line.f-modal-long,
.f-modal-icon.f-modal-error .f-modal-line.f-modal-long {
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 47px;
}

.f-modal-icon.f-modal-error {
  border-color: #F27474;
}

.f-modal-icon.f-modal-error .f-modal-x-mark {
  display: block;
  position: relative;
  z-index: 3;
}

.f-modal-icon.f-modal-error .f-modal-placeholder {
  border: 4px solid rgba(200, 0, 0, 0.2);
}

.f-modal-icon.f-modal-error .f-modal-line {
  background-color: #F27474;
  top: 37px;
  width: 47px;
}

.f-modal-icon.f-modal-error .f-modal-line.f-modal-left {
  left: 17px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.f-modal-icon.f-modal-error .f-modal-line.f-modal-right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.f-modal-icon.f-modal-warning {
  border-color: #F8BB86;
}

.f-modal-icon.f-modal-warning:before {

  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.f-modal-icon.f-modal-warning:after {
  background-color: #fff;
  border-radius: 50%;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.f-modal-icon.f-modal-warning .f-modal-body {
  background-color: #F8BB86;
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
  z-index: 2;
}

.f-modal-icon.f-modal-warning .f-modal-dot {
  background-color: #F8BB86;
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
  z-index: 2;
}

.f-modal-icon + .f-modal-icon {
  margin-top: 50px;
}

.animateSuccessTip {
  -webkit-animation: animateSuccessTip .75s;
          animation: animateSuccessTip .75s;
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong .75s;
          animation: animateSuccessLong .75s;
}

.f-modal-icon.f-modal-success.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
          animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
          animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
  -webkit-animation: animateErrorIcon .5s;
          animation: animateErrorIcon .5s;
}

.animateXLeft {
  -webkit-animation: animateXLeft .75s;
          animation: animateXLeft .75s;
}

.animateXRight {
  -webkit-animation: animateXRight .75s;
          animation: animateXRight .75s;
}

.f-modal-success {
  -webkit-animation: scaleWarning 0.75s infinite alternate;
          animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
          animation: pulseWarningIns 0.75s infinite alternate;
}

@-webkit-keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}

@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}

@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}

@-webkit-keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

@keyframes scaleWarning {
	0% {
		transform: scale(1);
	}

	30% {
		transform: scale(1.02);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes pulseWarning {
	0% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	30% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		background-color: rgb(165, 220, 134);
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}



.f-modal-alert {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 20px;
  padding: 50px;
}
</style>